import type { XRequest, XResponse } from '../types';

export interface RequestErrorOptions {
  request: XRequest;
  response?: XResponse;
  cause?: Error;
  pendingPromise?: Promise<unknown>;
  isTimeoutError?: boolean;
  isAbortError?: boolean;
}

class RequestError extends Error {
  readonly name = 'RequestError';

  readonly isRequestError = true;

  readonly isTimeoutError: boolean;

  readonly isAbortError: boolean;

  readonly request: XRequest;

  readonly response?: XResponse;

  readonly pendingPromise?: Promise<unknown>;

  constructor(message: string, options: RequestErrorOptions) {
    const {
      request,
      response,
      cause,
      pendingPromise,
      isTimeoutError = false,
      isAbortError = false,
    } = options;
    super(message);

    this.request = request;
    this.response = response;
    this.cause = cause;
    this.pendingPromise = pendingPromise;
    this.isTimeoutError = isTimeoutError;
    this.isAbortError = isAbortError;
  }
}

export function isRequestError(target: any): target is RequestError {
  const candidate = target as RequestError;

  return Boolean(
    candidate &&
      candidate.isRequestError &&
      candidate.name === 'RequestError' &&
      typeof candidate.request === 'object' &&
      typeof candidate.request.url === 'string',
  );
}

export default RequestError;
