export function disposable(fn: () => void) {
  let disposed = false;

  const dispose = () => {
    if (!disposed) {
      fn();

      disposed = true;

      return true;
    }

    return false;
  };

  return dispose;
}

export type Middleware<T, R> = (context: T, next: () => R) => R;

export function compose<T, R>(...middlewares: Middleware<T, R>[]): Middleware<T, R> {
  if (middlewares.length === 0) {
    return (_, next) => {
      return next();
    };
  }

  return middlewares.reduceRight((result, middleware) => {
    return (context, next) => {
      return result(context, () => middleware(context, next));
    };
  });
}

export type Refinable<TTarget> = TTarget extends (...args: any[]) => infer TReturnType
  ? TReturnType | TTarget
  : TTarget;

export type RefinableParameters<TTarget> = TTarget extends (...args: infer TParameters) => any
  ? TParameters
  : never;

export type RefinableReturnType<TTarget> = TTarget extends (...args: any[]) => infer TReturnType
  ? TReturnType
  : TTarget;

export function refine<TTarget = any>(
  target: TTarget,
  ...args: RefinableParameters<TTarget>
): RefinableReturnType<TTarget> {
  return typeof target === 'function' ? target(...args) : target;
}
