import omit from 'lodash/omit';
import type {
  XRequestDefinition,
  XRequestOptions,
  XResponse,
  XResponseDefinition,
  XEndpoint,
  XMiddleware,
  XRequestConfig,
  XContext,
  XRequestInstance,
  XRequestGlobalConfig,
} from '../types';
import { compose, disposable, refine, type Refinable } from '../utils/function';
import RequestError, { isRequestError } from './RequestError';
import { getRoute } from './route';
import AxiosAdapter from '../adapters/Axios';

export function mergeConfig<T extends Record<any, any>>(
  prev: T,
  next: Partial<T>,
  replace?: boolean,
): T {
  const merged: Record<any, any> = {
    ...prev,
  };

  for (const [key, value] of Object.entries(next)) {
    if (value !== undefined) {
      if (replace) {
        merged[key] = value;
      } else {
        const prevValue = merged[key];

        if (Array.isArray(value) && Array.isArray(prevValue)) {
          merged[key] = [...prevValue, ...value];
        } else if (typeof value === 'object' && typeof prevValue === 'object') {
          merged[key] = { ...(prevValue ?? {}), ...value };
        } else {
          merged[key] = value;
        }
      }
    }
  }

  return merged;
}

export function create(config: XRequestConfig = {}): XRequestInstance {
  function extend(config: XRequestConfig = {}) {
    return create(mergeConfig(request.defaults, config));
  }

  function provide(
    config: Refinable<(prev: XRequestGlobalConfig) => XRequestConfig>,
    replace: boolean = typeof config === 'function',
  ) {
    const nextConfig = refine(config, request.defaults);

    request.defaults = mergeConfig(request.defaults, nextConfig, replace);

    return request.defaults;
  }

  function use(middleware: XMiddleware) {
    request.defaults.middlewares.push(middleware);

    return disposable(() => {
      const index = request.defaults.middlewares.indexOf(middleware);

      if (index > -1) {
        request.defaults.middlewares.splice(index, 1);
      }
    });
  }

  async function request<
    TResponseDefinition extends XResponseDefinition = XResponseDefinition,
    TRequestDefinition extends XRequestDefinition = XRequestDefinition,
  >(endpoint: XEndpoint, options: XRequestOptions<TRequestDefinition> = {}) {
    const route = getRoute(endpoint);
    const globalOptions: XRequestOptions<TRequestDefinition> = omit(
      request.defaults,
      'adapter',
      'middlewares',
    );
    const mergedOptions = mergeConfig(globalOptions, options);
    const context: XContext = {
      route,
      request: {
        ...mergedOptions,
        method: route.method ?? mergedOptions.method,
        url: route.render(mergedOptions.params),
      },
    };

    const execute = compose(...request.defaults.middlewares);

    await execute(context, async () => {
      try {
        context.response = await request.defaults.adapter(context.request, context.route);
      } catch (error) {
        if (isRequestError(error) && error.response) {
          context.response = error.response;
        }

        throw error;
      }
    });

    if (!context.response) {
      throw new RequestError(
        'no response found, please make sure the middlewares or adapter is correct',
        {
          request: context.request,
        },
      );
    }

    return context.response as XResponse<TResponseDefinition, TRequestDefinition>;
  }

  request.defaults = {
    ...config,
    adapter: config.adapter ?? AxiosAdapter(),
    middlewares: [...(config.middlewares ?? [])],
  };
  request.extend = extend;
  request.provide = provide;
  request.use = use;

  return request;
}

export const request = create();
