import axios, { type AxiosInstance, type Method } from 'axios';
import type { XAdapter } from '../types';
import RequestError from '../core/RequestError';

function AxiosAdapter(
  instance: AxiosInstance = axios.create({
    transitional: {
      clarifyTimeoutError: true,
    },
  }),
): XAdapter {
  return async (request) => {
    try {
      const response = await instance.request({
        baseURL: request.baseURL,
        method: request.method as Method,
        url: request.url,
        headers: request.headers,
        params: request.query,
        data: request.data,
        timeout: request.timeout,
        signal: request.signal,
      });

      const { status, headers, data } = response;

      return {
        status,
        headers,
        data,
        request,
      };
    } catch (error) {
      const candidate = error as Error;

      throw new RequestError(candidate?.message ?? 'axios request error', {
        request,
        ...(axios.isAxiosError(candidate)
          ? {
              response: candidate.response
                ? {
                    request,
                    status: candidate.response.status,
                    headers: candidate.response.headers,
                    data: candidate.response.data,
                  }
                : undefined,
              isTimeoutError: candidate.code === axios.AxiosError.ETIMEDOUT,
              isAbortError: candidate.code === axios.AxiosError.ECONNABORTED,
            }
          : {}),
        cause: candidate,
      });
    }
  };
}

export default AxiosAdapter;
