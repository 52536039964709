export const locationContext = {
  location() {
    const {
      location: { pathname, search, hash },
    } = window;

    return `<${pathname}${search}${hash}>`;
  },
};

export const pidContext = {
  pid() {
    return `<${process.pid}>`;
  },
};
