import debug from 'debug';
import type { Logger, LoggerContext, LoggerMeta, LogLevel } from './types';
import NoopLogger from './NoopLogger';

class DebugLogger extends NoopLogger implements Logger {
  readonly namespace: string;

  private _context: LoggerContext;

  private _logger: Logger;

  constructor(namespace: string, context: LoggerContext = {}) {
    super();

    this.namespace = namespace;
    this._context = context;
    this._logger = {
      silly: debug(`${namespace}:silly`),
      debug: debug(`${namespace}:debug`),
      verbose: debug(`${namespace}:verbose`),
      info: debug(`${namespace}:info`),
      warn: debug(`${namespace}:warn`),
      error: debug(`${namespace}:error`),
    };
  }

  disable() {
    debug.disable();

    return this;
  }

  enable(...logLevels: LogLevel[]) {
    if (logLevels.length === 0) {
      debug.enable(`${this.namespace}:*`);
    } else {
      debug.enable(logLevels.map((logLevel) => `${this.namespace}:${logLevel}`).join(','));
    }

    return this;
  }

  extend(namespace: string, delimiter = '/') {
    return new DebugLogger(
      this.namespace ? `${this.namespace}${delimiter}${namespace}` : namespace,
      this._context,
    );
  }

  getContext() {
    return this._context;
  }

  setContext(context: LoggerContext | ((context: LoggerContext) => LoggerContext)) {
    const nextContext = typeof context === 'function' ? context(this._context) : context;

    this._context = nextContext;

    return this;
  }

  log(meta: Partial<LoggerMeta>): void;

  log(level: LogLevel, message?: any, ...optionalParams: any[]): void;

  log(maybeLevel: any, maybeMessage?: any, ...optionalParams: any[]) {
    let meta: LoggerMeta;

    const context = Object.entries(this._context).reduce<Record<string, string>>(
      (result, [key, value]) => {
        const text = String(typeof value === 'function' ? value() : value).trim();

        if (text) {
          // eslint-disable-next-line no-param-reassign
          result[key] = text;
        }

        return result;
      },
      {},
    );

    if (typeof maybeLevel === 'object') {
      const {
        name = this.namespace,
        level = 'info',
        message = '',
        params = [],
        ...otherMeta
      } = maybeLevel;

      meta = {
        ...context,
        ...otherMeta,
        name,
        level,
        message,
        params,
      };
    } else {
      meta = {
        ...context,
        name: this.namespace,
        level: maybeLevel,
        ...(typeof maybeMessage === 'string'
          ? {
              message: maybeMessage,
              params: optionalParams,
            }
          : {
              message: '',
              params: [maybeMessage, ...optionalParams],
            }),
      };
    }

    const { name, level, message, params, ...otherMeta } = meta;

    const prefix = Object.values(otherMeta).reduce<string[]>((result, value) => {
      switch (typeof value) {
        case 'number': {
          result.push(String(value));

          break;
        }
        case 'string': {
          const text = value.trim();

          if (text) {
            result.push(text);
          }

          break;
        }
        default: {
          break;
        }
      }

      return result;
    }, []);

    this._logger[level]([...prefix, ...(message.length > 0 ? [message] : [])].join(' '), ...params);
  }
}

export default DebugLogger;
