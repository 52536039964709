import type {
  Disposable,
  EmitterAnyHandle,
  EmitterEventName,
  EmitterHandle,
  EventHandle,
  EventName,
  EventsMap,
} from './types';
import { isOnceWrapper } from './utils';

const ANY_EVENT_SYMBOL = Symbol('AnyEvent');

class Emitter<TEventsMap extends EventsMap = EventsMap> {
  private _store: Map<EventName, EventHandle[]> = new Map();

  private _offUntyped(event: EventName, handle: EventHandle) {
    const handles = this._store.get(event);

    if (handles) {
      const index = handles.findIndex((innerHandle) => {
        return (
          handle === innerHandle || (isOnceWrapper(innerHandle) && innerHandle.origin === handle)
        );
      });

      if (index > -1) {
        handles.splice(index, 1);

        if (handles.length === 0) {
          this._store.delete(event);
        }

        return true;
      }
    }

    return false;
  }

  private _onUntyped(event: EventName, handle: EventHandle) {
    let handles = this._store.get(event);

    if (!handles) {
      handles = [];

      this._store.set(event, handles);
    }

    handles.push(handle);

    const dispose = () => {
      if (!dispose.disposed) {
        dispose.disposed = true;

        return this._offUntyped(event, handle);
      }

      return false;
    };

    dispose.disposed = false;

    return dispose as Disposable;
  }

  private _onceUntyped(event: EventName, handle: EventHandle) {
    const dispose = this._onUntyped(event, (...params) => {
      dispose();

      return handle(...params);
    });

    return dispose;
  }

  private _emitUntyped(event: EventName, ...params: any[]) {
    if (event !== ANY_EVENT_SYMBOL) {
      this._emitUntyped(ANY_EVENT_SYMBOL, event, params);
    }

    const result = [];
    const handles = this._store.get(event);

    if (handles) {
      const handlesSnapshot = [...handles];

      for (const handle of handlesSnapshot) {
        result.push(handle(...params));
      }
    }

    return result;
  }

  events() {
    const events = [];

    for (const key of this._store.keys()) {
      if (key !== ANY_EVENT_SYMBOL) {
        events.push(key);
      }
    }

    return events as EmitterEventName<TEventsMap>[];
  }

  handles<TEventName extends EmitterEventName<TEventsMap>>(event: TEventName) {
    const handles = this._store.get(event);

    return (handles ? [...handles] : []) as EmitterHandle<TEventsMap, TEventName>[];
  }

  anyHandles() {
    return this.handles(ANY_EVENT_SYMBOL) as EmitterAnyHandle<TEventsMap>[];
  }

  off<TEventName extends EmitterEventName<TEventsMap>>(
    event: TEventName,
    handle: EmitterHandle<TEventsMap, TEventName>,
  ) {
    return this._offUntyped(event, handle);
  }

  on<TEventName extends EmitterEventName<TEventsMap>>(
    event: TEventName,
    handle: EmitterHandle<TEventsMap, TEventName>,
  ) {
    return this._onUntyped(event, handle);
  }

  once<TEventName extends EmitterEventName<TEventsMap>>(
    event: TEventName,
    handle: EmitterHandle<TEventsMap, TEventName>,
  ) {
    return this._onceUntyped(event, handle);
  }

  offAny(handle: EmitterAnyHandle<TEventsMap>) {
    return this._offUntyped(ANY_EVENT_SYMBOL, handle);
  }

  onAny(handle: EmitterAnyHandle<TEventsMap>) {
    return this._onUntyped(ANY_EVENT_SYMBOL, handle);
  }

  onceAny(handle: EmitterAnyHandle<TEventsMap>) {
    return this._onceUntyped(ANY_EVENT_SYMBOL, handle);
  }

  emit<TEventName extends EmitterEventName<TEventsMap>>(
    event: TEventName,
    ...params: Parameters<EmitterHandle<TEventsMap, TEventName>>
  ) {
    const result = this._emitUntyped(event, ...params);

    return result as ReturnType<TEventsMap[TEventName]>[];
  }

  emitAsync<TEventName extends EmitterEventName<TEventsMap>>(
    event: TEventName,
    ...params: Parameters<EmitterHandle<TEventsMap, TEventName>>
  ) {
    const result = this.emit(event, ...params);

    return Promise.all(result);
  }
}

export default Emitter;
