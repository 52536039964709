import { Emitter } from '@leyan/emitter';
import type { Logger, LoggerMeta, LogLevel } from './types';
import type LoggerManager from './LoggerManager';
import NoopLogger from './NoopLogger';

type ProxyLoggerEventsMap = {
  logging(meta: LoggerMeta): void;
};

export interface ProxyLoggerOptions {
  name?: string;
  logger?: Logger;
  parent?: ProxyLogger;
  manager?: LoggerManager;
}

class ProxyLogger extends NoopLogger implements Logger {
  readonly name: string;

  readonly manager?: LoggerManager;

  private _parent?: ProxyLogger;

  private _logger?: Logger;

  private _emitter: Emitter<ProxyLoggerEventsMap> = new Emitter();

  constructor(options: ProxyLoggerOptions = {}) {
    const { name = '', logger, parent, manager } = options;

    super();

    this.name = name;
    this.manager = manager;
    this._logger = logger;
    this._parent = parent;

    this.onLogging((meta) => {
      if (this._logger) {
        const { name, level, message, params } = meta;

        this._logger[level](
          [
            ...(name.length > 0 ? [`[${name}]`] : []),
            ...(message.length > 0 ? [message] : []),
          ].join(' '),
          ...params,
        );

        return;
      }

      const proxyLogger = this._parent ?? this.manager?.getLogger();

      if (proxyLogger) {
        proxyLogger.log(meta);
      }
    });
  }

  extend(name: string, delimiter = '/') {
    const childName = this.name ? `${this.name}${delimiter}${name}` : name;

    if (this.manager) {
      const proxyLogger = this.manager.getLogger(childName);

      proxyLogger.setParent(this);

      return proxyLogger;
    }

    return new ProxyLogger({
      name: childName,
      parent: this,
    });
  }

  getParent() {
    return this._parent;
  }

  setParent(parent?: ProxyLogger) {
    this._parent = parent;

    return this;
  }

  getLogger() {
    return this._logger;
  }

  setLogger(logger?: Logger) {
    this._logger = logger;

    return this;
  }

  onLogging(handle: (meta: LoggerMeta) => void) {
    return this._emitter.on('logging', handle);
  }

  log(meta: Partial<LoggerMeta>): void;

  log(level: LogLevel, message?: any, ...optionalParams: any[]): void;

  log(maybeLevel: any, maybeMessage?: any, ...optionalParams: any[]) {
    let meta: LoggerMeta;

    if (typeof maybeLevel === 'object') {
      const {
        name = this.name,
        level = 'info',
        message = '',
        params = [],
        ...context
      } = maybeLevel;

      meta = {
        ...context,
        name,
        level,
        message,
        params,
      };
    } else {
      meta = {
        name: this.name,
        level: maybeLevel,
        ...(typeof maybeMessage === 'string'
          ? {
              message: maybeMessage,
              params: optionalParams,
            }
          : {
              message: '',
              params: [maybeMessage, ...optionalParams],
            }),
      };
    }

    this._emitter.emit('logging', meta);
  }
}

export default ProxyLogger;
