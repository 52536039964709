// 参考 `core-js` 实现 https://github.com/zloirock/core-js/blob/master/packages/core-js/internals/global.js

import type { Global } from './types';

export function isGlobal(target: any): false | Global {
  if (target && target.Math === Math) {
    return target;
  }

  return false;
}

export default isGlobal(typeof globalThis === 'object' && globalThis) ||
  isGlobal(typeof window === 'object' && window) ||
  // eslint-disable-next-line no-restricted-globals
  isGlobal(typeof self === 'object' && self) ||
  isGlobal(typeof global === 'object' && global) ||
  isGlobal(
    (function getGlobal(this: any) {
      return this;
    })(),
  ) ||
  // eslint-disable-next-line no-new-func
  isGlobal(new Function('return this')()) ||
  {};
