import escapeRegExp from 'lodash/escapeRegExp';

import type { XEndpoint, XRoute } from '../types';
import { matchEach, type MatchEachResult } from '../utils/string';

export const ENDPOINT_ROUTE_REG = /^\s*(?:([\S]+)\s+)?(.*?)\s*$/;

export const ENDPOINT_VARIABLE_REG = /\{\s*([^{}/\\]+?)\s*\}/;

export const ROUTES_CACHE = new Map<XEndpoint, XRoute>();

export function parseRoute(endpoint: XEndpoint): XRoute {
  const [, method, path] = ENDPOINT_ROUTE_REG.exec(endpoint)!;
  const params: string[] = [];
  let url: string;
  let match: XRoute['match'];
  let render: XRoute['render'];

  if (ENDPOINT_VARIABLE_REG.test(path)) {
    const fragments: MatchEachResult[] = [];
    const urlSegments: string[] = [];
    const matchRegSegments: string[] = [];

    for (const result of matchEach(path, ENDPOINT_VARIABLE_REG)) {
      const { text, matched } = result;

      fragments.push(result);

      if (matched) {
        const name = matched[1];

        params.push(name);

        urlSegments.push(`{${name}}`);

        matchRegSegments.push('(.+)');
      } else {
        urlSegments.push(text);

        matchRegSegments.push(escapeRegExp(text));
      }
    }

    const matchReg = new RegExp(`^${matchRegSegments.join('')}$`);

    url = urlSegments.join('');

    match = (next) => {
      const matched = matchReg.exec(next.trim());

      if (matched) {
        return params.reduce<Record<string, string>>((result, name, index) => {
          // eslint-disable-next-line no-param-reassign
          result[name] = matched[index + 1];

          return result;
        }, {});
      }

      return false;
    };

    render = (params = {}) => {
      return fragments
        .map((fragment) => {
          const { text, matched } = fragment;

          if (matched) {
            const name = matched[1];
            const value = params[name];

            if (value !== undefined) {
              return String(value).trim();
            }
          }

          return text;
        })
        .join('');
    };
  } else {
    url = path;

    match = (next) => {
      return next.trim() === url ? {} : false;
    };

    render = () => {
      return url;
    };
  }

  return {
    method,
    url,
    params,
    match,
    render,
  };
}

export function getRoute(endpoint: XEndpoint) {
  let route = ROUTES_CACHE.get(endpoint);

  if (!route) {
    route = parseRoute(endpoint);

    ROUTES_CACHE.set(endpoint, route);
  }

  return route;
}

export function isRouteEqual(a: XRoute, b: XRoute) {
  return a.method?.toUpperCase() === b.method?.toUpperCase() && a.url === b.url;
}
