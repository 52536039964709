import type {
  XEndpoint,
  XMiddleware,
  XRequest,
  XRequestDefinition,
  XResponse,
  XResponseDefinition,
  XRoute,
} from '../types';
import { refine, type Refinable } from '../utils/function';
import RequestError from '../core/RequestError';
import { getRoute } from '../core/route';

export interface MockResponse<TResponseDefinition extends XResponseDefinition = XResponseDefinition>
  extends Omit<XResponse<TResponseDefinition>, 'request'> {}

export interface MockTransform<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
> {
  (response: MockResponse<TResponseDefinition>):
    | MockResponse<TResponseDefinition>
    | Promise<MockResponse<TResponseDefinition>>;
}

export interface MockResolver<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
  TRequestDefinition extends XRequestDefinition = XRequestDefinition,
> {
  (request: XRequest<TRequestDefinition>):
    | MockResponse<TResponseDefinition>
    | Promise<MockResponse<TResponseDefinition>>;
}

export type MockResolverEntry<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
  TRequestDefinition extends XRequestDefinition = XRequestDefinition,
> = [key: string, resolver: MockResolver<TResponseDefinition, TRequestDefinition>];

export class MockRequestError extends Error {
  readonly name = 'MockRequestError';

  readonly isMockRequestError = true;

  readonly response?: MockResponse;

  constructor(message: string, response?: MockResponse) {
    super(message);

    this.response = response;
  }
}

export function isMockRequestError(target: any): target is MockRequestError {
  const candidate = target as MockRequestError;

  return Boolean(
    candidate && candidate.isMockRequestError && candidate.name === 'MockRequestError',
  );
}

function getRouteKey(route: XRoute) {
  const { method, url } = route;

  return `${method ? `${method.toUpperCase()} ` : ''}${url}`;
}

function status<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  status: Refinable<(prev?: unknown) => TResponseDefinition['status']>,
): MockTransform<TResponseDefinition> {
  return (response) => {
    response.status = refine(status, response.status);

    return response;
  };
}

function headers<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  headers: Refinable<(prev?: unknown) => TResponseDefinition['headers']>,
): MockTransform<TResponseDefinition> {
  return (response) => {
    response.headers = refine(headers, response.headers);

    return response;
  };
}

function data<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  data: Refinable<(prev?: unknown) => TResponseDefinition['data']>,
): MockTransform<TResponseDefinition> {
  return (response) => {
    response.data = refine(data, response.data);

    return response;
  };
}

function extra<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  extra: Refinable<(prev?: unknown) => TResponseDefinition['extra']>,
): MockTransform<TResponseDefinition> {
  return (response) => {
    response.extra = refine(extra, response.extra);

    return response;
  };
}

function error<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  message: string,
): MockTransform<TResponseDefinition> {
  return (response) => {
    throw new MockRequestError(message, response);
  };
}

function delay<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  time: number,
): MockTransform<TResponseDefinition> {
  return async (response) => {
    await new Promise<void>((resolve) => {
      setTimeout(resolve, time);
    });

    return response;
  };
}

export function transform<TResponseDefinition extends XResponseDefinition = XResponseDefinition>(
  response: Refinable<
    (prev?: MockResponse<TResponseDefinition>) => MockResponse<TResponseDefinition>
  >,
): MockTransform<TResponseDefinition> {
  return (prev) => {
    return refine(response, prev);
  };
}

transform.status = status;

transform.headers = headers;

transform.data = data;

transform.extra = extra;

transform.error = error;

transform.delay = delay;

export async function response<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
>(...transform: MockTransform<TResponseDefinition>[]) {
  return transform.reduce<Promise<MockResponse<TResponseDefinition>>>(
    async (result, transform) => {
      return transform(await result);
    },
    Promise.resolve({
      status: 200,
      headers: {},
      data: undefined,
    }),
  );
}

function skip<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
  TRequestDefinition extends XRequestDefinition = XRequestDefinition,
>(_endpoint: XEndpoint, _resolver: MockResolver<TResponseDefinition, TRequestDefinition>) {
  return false as const;
}

export function when<
  TResponseDefinition extends XResponseDefinition = XResponseDefinition,
  TRequestDefinition extends XRequestDefinition = XRequestDefinition,
>(
  endpoint: XEndpoint,
  resolver: MockResolver<TResponseDefinition, TRequestDefinition>,
): MockResolverEntry<TResponseDefinition, TRequestDefinition> {
  const route = getRoute(endpoint);
  const routeKey = getRouteKey(route);

  return [routeKey, resolver];
}

when.skip = skip;

function MockMiddleware(
  ...entries: (MockResolverEntry<any, any> | false | null | undefined)[]
): XMiddleware {
  const resolvers = new Map<string, MockResolver<any, any>>(
    entries.filter((entry) => {
      return Boolean(entry);
    }) as MockResolverEntry<any, any>[],
  );

  return async (context, next) => {
    const routeKey = getRouteKey(context.route);

    const resolver = resolvers.get(routeKey);

    if (resolver) {
      const promises: Promise<void>[] = [];
      const cleanups: (() => void)[] = [];

      promises.push(
        Promise.resolve().then(async () => {
          try {
            const response = await resolver(context.request);

            context.response = {
              ...response,
              request: context.request,
            };
          } catch (error) {
            const candidate = error as Error;

            throw new RequestError(candidate?.message ?? 'mock request error', {
              request: context.request,
              response:
                isMockRequestError(candidate) && candidate.response
                  ? {
                      ...candidate?.response,
                      request: context.request,
                    }
                  : undefined,
              cause: candidate,
            });
          }
        }),
      );

      const {
        request: { timeout, signal },
      } = context;

      if (timeout !== undefined) {
        promises.push(
          new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
              reject(
                new RequestError(`mock request timeout in ${timeout}ms`, {
                  request: context.request,
                  isTimeoutError: true,
                  pendingPromise: promises[0],
                }),
              );
            }, timeout);

            cleanups.push(() => {
              resolve();

              clearTimeout(timeoutId);
            });
          }),
        );
      }

      if (signal !== undefined) {
        promises.push(
          new Promise((resolve, reject) => {
            const handleAbort = () => {
              reject(
                new RequestError('mock request aborted', {
                  request: context.request,
                  isAbortError: true,
                }),
              );
            };

            if (signal.aborted) {
              handleAbort();

              return;
            }

            signal.addEventListener('abort', handleAbort);

            cleanups.push(() => {
              resolve();

              signal.removeEventListener('abort', handleAbort);
            });
          }),
        );
      }

      try {
        await Promise.race(promises);
      } finally {
        for (const cleanup of cleanups) {
          cleanup();
        }
      }

      return;
    }

    await next();
  };
}

export default MockMiddleware;
