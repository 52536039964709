export interface MatchEachResult {
  text: string;
  index: number;
  matched: RegExpExecArray | false;
}

export function* matchEach(text: string, reg: RegExp) {
  const globalReg = new RegExp(reg.source, `${reg.flags}${reg.global ? '' : 'g'}`);
  let current = 0;
  let matched;

  do {
    matched = globalReg.exec(text);

    if (matched) {
      const { 0: matchedText, index } = matched;

      if (index > current) {
        yield {
          text: text.slice(current, index),
          index: current,
          matched: false,
        } as MatchEachResult;
      }

      yield { text: matchedText, index, matched } as MatchEachResult;

      current = index + matchedText.length;
    } else if (current < text.length) {
      yield { text: text.slice(current), index: current, matched: false } as MatchEachResult;
    }
  } while (matched);
}

export type MatchPattern = string | RegExp;

export function isMatchPattern(text: string, pattern: MatchPattern) {
  if (typeof pattern === 'string') {
    return text === pattern;
  }

  return pattern.test(text);
}

export interface MatchOptions {
  include?: MatchPattern[];
  exclude?: MatchPattern[];
}

export function isMatch(text: string, options: MatchOptions = {}) {
  const { include, exclude } = options;

  if (include && include?.length > 0) {
    for (const pattern of include) {
      if (!isMatchPattern(text, pattern)) {
        return false;
      }
    }
  }

  if (exclude && exclude.length > 0) {
    for (const pattern of exclude) {
      if (isMatchPattern(text, pattern)) {
        return false;
      }
    }
  }

  return true;
}
