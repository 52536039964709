export interface OnceWrapper<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T>;
  readonly origin: T;
  readonly invoked: boolean;
  readonly result: ReturnType<T> | undefined;
}

export function once<T extends (...args: any[]) => any>(fn: T) {
  const wrapper = (...args: Parameters<T>) => {
    if (!wrapper.invoked) {
      wrapper.invoked = true;

      wrapper.result = fn(...args);
    }

    return wrapper.result as ReturnType<T>;
  };

  wrapper.origin = fn;
  wrapper.invoked = false;
  wrapper.result = undefined as T | undefined;

  return wrapper as OnceWrapper<T>;
}

export function isOnceWrapper(target: any): target is OnceWrapper<any> {
  const candidate = target as OnceWrapper<any>;

  return (
    typeof candidate === 'function' &&
    typeof candidate.origin === 'function' &&
    typeof candidate.invoked === 'boolean'
  );
}
