import global from './global';
import LoggerManager from './LoggerManager';

export const GLOBAL_LOGGER_MANAGER_SYMBOL = Symbol.for('__GLOBAL_LOGGER_MANAGER__');

let GLOBAL_LOGGER_MANAGER: LoggerManager = (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL];

if (!GLOBAL_LOGGER_MANAGER) {
  GLOBAL_LOGGER_MANAGER = new LoggerManager();

  if (typeof Object.defineProperty === 'function') {
    Object.defineProperty(global, GLOBAL_LOGGER_MANAGER_SYMBOL, {
      get() {
        return GLOBAL_LOGGER_MANAGER;
      },
    });
  } else {
    (global as any)[GLOBAL_LOGGER_MANAGER_SYMBOL] = GLOBAL_LOGGER_MANAGER;
  }
}

export const loggerManager = GLOBAL_LOGGER_MANAGER;
