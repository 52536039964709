import type { Logger, LoggerMeta, LogLevel } from './types';

class NoopLogger implements Logger {
  log(meta: Partial<LoggerMeta>): void;

  log(level: LogLevel, message?: any, ...optionalParams: any[]): void;

  // eslint-disable-next-line class-methods-use-this
  log(_maybeLevel: any, _maybeMessage?: any, ..._optionalParams: any[]) {}

  silly(message?: any, ...optionalParams: any[]) {
    this.log('silly', message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]) {
    this.log('debug', message, ...optionalParams);
  }

  verbose(message?: any, ...optionalParams: any[]) {
    this.log('verbose', message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    this.log('info', message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    this.log('warn', message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]) {
    this.log('error', message, ...optionalParams);
  }
}

export default NoopLogger;
